// 获取确认订单信息
import consumer_trains_order_trainOrderTakeTicketDetail from '@/lib/data-service/haolv-default/consumer_trains_order_trainOrderTakeTicketDetail'
// 取消订单
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel'
export default {
    data () {
        return {
          orderNoText: null,
          nameData: '',
          dialogVisible: false,
          timeData: 5,
          setTimeBox: null,
          setNameOff: 0,
          // priceInfoList:[
          //   {
          //     servicePrice: '20',
          //     robTicketPrice: '20',
          //     headCounts: 1,
          //   }
          // ],
          priceInfoList:[

          ],
          trainData: {
            trainInfo:{
              trainNo: ''
            },
            orderInfo: {
              orderStatusText: ''
            }
          },
        }
    },
    components: {

    },
    created () {

    },
    mounted () {

    },
    activated () {
      this.timeData = 5;
      this.priceInfoList = [];
      this.initPost();
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
      //确认取消
      buttonClickExitGo(){
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let data = {
          orderNo:this.orderNoText
        };
        consumer_trains_order_applyForCancel(data)
            .then((res) => {
              if (res.rsCode == '000000') {
                this.$message({
                  message: '取消订单成功',
                  type: 'success'
                });
              } else {
                this.$message.error('取消订单失败');
              }
              loading.close();
              this.dialogVisible = false;

              setTimeout(() => {
                this.$router.push({
                  name: "admin-my-order-train",
                });
              },1000)
            })
            .catch((res) => {
              this.$message.error('取消订单失败');
              loading.close();
              this.dialogVisible = false;

              setTimeout(() => {
                this.$router.push({
                  name: "admin-my-order-train",
                });
              },1000)
            })
      },
      //退出提示框
      buttonClickExitExit(){
        this.initTime();
      },
      // 取消订单按钮
      buttonClickExit(){
        clearInterval(this.setTimeBox);
        let nameData = '';
        this.trainData.passengerInfos.forEach((item,index) => {
          if (item.takeTicketStatusText == '占座成功') {
            nameData = nameData + item.passengerName + '、';
          }
        });

        this.nameData = nameData.slice(0,nameData.length-1);
        this.dialogVisible = true;
      },
      //去支付按钮
      buttonClickGo(){
        clearInterval(this.setTimeBox);
        let evectionNo = this.$route.query.evectionNo;
        this.$router.push({
          name: 'admin-payment',
          query: {
            evectionNo
          }
        })
      },
      initTime(){
        this.setTimeBox = setInterval(() => {
          --this.timeData;
          if (this.timeData == 0) {
            clearInterval(this.setTimeBox);
            let evectionNo = this.$route.query.evectionNo;
            this.$router.push({
              name: 'admin-payment',
              query: {
                evectionNo
              }
            })
          }
        },1000)
      },
      initPost(){
        let data = {
          orderNo:this.$route.query.orderNo
        };
        this.orderNoText = data.orderNo;
        consumer_trains_order_trainOrderTakeTicketDetail(data).then((res) => {
          this.trainData = res.datas;
          this.initTime();

          this.priceInfoList.push(
            {
              seatName: '费用总计',
              seatPrice: this.trainData.orderInfo.buyerPaymentTotalAmount,
              headCounts: null,
            },
            {
              seatName: '抢票费',
              seatPrice: this.trainData.priceInfo.robTicketPrice,
              headCounts: this.trainData.priceInfo.headCounts,
            },
            {
              seatName: '服务费',
              seatPrice: this.trainData.priceInfo.servicePrice,
              headCounts: this.trainData.priceInfo.headCounts,
            }
          );

          this.trainData.priceInfo.ticketPriceInfos.forEach((item,index) => {
            this.priceInfoList.push(item);
          });
        })
      }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
